import React from "react";
import styled, { keyframes } from "styled-components";
import constants from "../Shared/constants";

const SpinningCube: React.FC = () => {
  return (
    <Scene>
      <Cube>
        <Front>Di</Front>
        <Back>Lo</Back>
        <Left>Gi</Left>
        <Right>Gi</Right>
        <Top>Ka</Top>
        <Bottom>Ka</Bottom>
      </Cube>
    </Scene>
  );
};

export default SpinningCube;

const spin = keyframes`
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }

  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
`;

const zOffset = 100;

const CubeFace = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 200px;
  color: ${constants.colorTernary};
  border: 8px dashed ${constants.colorHighlightGreen};
  background-color: rgb(16 100 46 / 20%);
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;

  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;

  &:hover {
    width: 150px;
    height: 150px;
  }
`;

const Scene = styled.div`
  width: 1px;
  height: 1px;
  perspective: 900px;
  display: flex;
`;

const Cube = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateZ(-100px);
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  transform-box: border-box;
  animation: ${spin} 20s linear 0s infinite forwards;
`;

const Front = styled(CubeFace)`
  transform: translateZ(${zOffset}px);
`;

const Back = styled(CubeFace)`
  transform: rotateY(180deg) translateZ(${zOffset}px);
`;

const Left = styled(CubeFace)`
  transform: rotateY(-90deg) translateZ(${zOffset}px);
`;

const Right = styled(CubeFace)`
  transform: rotateY(90deg) translateZ(${zOffset}px);
`;

const Top = styled(CubeFace)`
  transform: rotateX(90deg) translateZ(${zOffset}px);
`;

const Bottom = styled(CubeFace)`
  transform: rotateX(-90deg) translateZ(${zOffset}px);
`;
