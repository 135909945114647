import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Navigation from "./Navigation/Navigation";
import constants, { device } from "../Shared/constants";

const Sidebar: React.FC = () => {
  return (
    <SidebarWrap>
      <Logo />
      <Navigation />
      {/* <BuyMeACoffee /> */}
    </SidebarWrap>
  );
};

const SidebarWrap = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: row;
  background-color: ${constants.colorBgSidebar};

  @media ${device.tablet} {
    grid-area: sidebar;
    flex-direction: column;
    padding: 10px 50px;
  }
`;

export default Sidebar;
