import React from "react";
import Section, { Highlight, SectionArea, SectionGridVariant } from "../Section";
import { useTranslation, Trans } from "react-i18next";
import NumbersDisplay from "../NumbersDisplay";
import InfoField from "../InfoField";
import Markdown from "../Markdown";
import styled from "styled-components";
import { device } from "../../Shared/constants";
import { PageData } from "../../Typings/Page";

const AboutSection: React.FC<PageData> = ({ content, infoFields, numbers }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t("Who is Digilogika ?")}
      titleBackdrop={t("About")}
      colorVariant="dark"
      gridVariant={SectionGridVariant.ABOUT}
    >
      <SectionArea area="content">
        <h3>
          <Trans i18nKey="sectionTitleAbout" values={{ Toomas: "Toomas" }}>
            Hi. I'm <Highlight>Toomas</Highlight>. I program stuff.
          </Trans>
        </h3>
        <Markdown content={content} />
      </SectionArea>
      <SectionArea area="sidebar" style={sidebarStyle}>
        {infoFields.map((item, idx) => (
          <InfoField key={idx} {...item} />
        ))}
      </SectionArea>
      <NumbersSection area="numbers">
        {numbers.map((item, idx) => (
          <NumbersDisplay key={idx} number={item.number} text={item.text} />
        ))}
      </NumbersSection>
    </Section>
  );
};

const NumbersSection = styled(SectionArea)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${device.laptop} {
    justify-content: center;
  }
`;

const sidebarStyle: React.CSSProperties = {
  paddingTop: "45px",
};

export default AboutSection;
