import React from "react";
import styled from "styled-components";
import constants, { device } from "../Shared/constants";

const View: React.FC = ({ children }) => {
  return <ViewWrap>{children}</ViewWrap>;
};

const ViewWrap = styled.div`
  display: flex;
  grid-area: content;
  background-color: ${constants.colorBgDefault};
  color: ${constants.colorText};
  overflow: auto;
  width: 100%;

  @media ${device.laptop} {
    justify-content: center;
  }
`;

export const ViewContent = styled.div`
  align-content: center;
  width: 100%;
`;

export default View;
