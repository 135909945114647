import React from "react";
import Loader from "../Components/Loader";
import View, { ViewContent } from "../Components/View";
import HeroSection from "../Components/Sections/HeroSection";

const HomeView: React.FC = () => {
  return (
    <View>
      <ViewContent>
        <HeroSection mainContent="Hero section" />
      </ViewContent>
    </View>
  );
};

export default HomeView;
