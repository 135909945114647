import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import constants from "../Shared/constants";

export interface InfoFieldProps {
  icon?: React.ReactElement;
  label: string;
  content: string;
  email?: string;
  url?: string;
}

const InfoField: React.FC<InfoFieldProps> = ({ label, content, url, email, icon }) => {
  const [t] = useTranslation();

  return (
    <Wrap>
      {icon || <Label>{t(label)}&nbsp;:</Label>}
      &nbsp;<Value> {renderValue(content, url, email)}</Value>
    </Wrap>
  );
};

function renderValue(content: string, url?: string, email?: string): string | React.ReactElement {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {content || url}
      </a>
    );
  }

  if (email) {
    return (
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {content || url}
      </a>
    );
  }

  return content || "";
}

const Wrap = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid rgb(100 100 100 / 20%);

  &:last-of-type {
    border-bottom: none;
  }
`;

const Label = styled.div`
  font-weight: 600;
  font-size: ${constants.fontSizeSmall};
`;

const Value = styled.div`
  font-weight: 400;

  a {
    color: ${constants.colorHighlightGreen};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default InfoField;
