import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import constants, { device } from "../Shared/constants";
import LogoIcon from "./icons/LogoIcon";

const Logo: React.FC = () => {
  return (
    <LogoWrap to="/">
      <LogoIconWrap>
        <LogoIcon width="60" fill={constants.colorHighlightGreen} />
      </LogoIconWrap>
      <h1>Digilogika</h1>
    </LogoWrap>
  );
};

const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
  }

  > h1 {
    font-family: "Teko", sans-serif;
    font-weight: 600;
    letter-spacing: 3px;
    margin: 0;
    font-size: 30px;
    padding-top: 3px;
    color: ${constants.colorText};

    @media ${device.tablet} {
      font-size: 50px;
    }
  }
`;

const LogoIconWrap = styled.div`
  transition: filter 0.7s;

  > svg {
    height: 40px;
    @media ${device.tablet} {
      height: initial;
    }
  }

  ${LogoWrap}:hover & {
    filter: drop-shadow(-16px 0px 0px rgba(255, 44, 249, 0.5)) drop-shadow(-8px 0px 0px ${constants.colorText})
      blur(0.8px);
  }
`;

export default Logo;
