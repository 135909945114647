import React from "react";
import Section, { SectionArea, SectionGridVariant, TitleBackdropCss } from "../Section";
import SpinningCube from "../SpinningCube";
import styled from "styled-components";

interface HeroSectionProps {
  mainContent: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ mainContent }) => {
  return (
    <Section colorVariant="dark" gridVariant={SectionGridVariant.HERO}>
      <SectionArea area="content">
        <CubeWrap>
          <SpinningCube />
        </CubeWrap>
      </SectionArea>
    </Section>
  );
};

const CubeWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70vh;

  &:before {
    content: "Digilogika";
    ${TitleBackdropCss}
  }
`;

export default HeroSection;
