import Sidebar from "./Components/Sidebar";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import HomeView from "./Views/HomeView";
// import BlogView from "./Views/BlogView";
import AboutView from "./Views/AboutView";
import constants, { device } from "./Shared/constants";
import i18n from "./Services/i18n";
// import ProjectsView from "./Views/ProjectsView";

i18n();

function App() {
  return (
    <AppWrap>
      <Sidebar />
      <Switch>
        <Route path="/" exact component={HomeView} />
        <Route path="/about" exact component={AboutView} />
        {/* <Route path="/blog" exact component={BlogView} /> */}
        {/* <Route path="/projects" exact component={ProjectsView} /> */}
      </Switch>
    </AppWrap>
  );
}

const AppWrap = styled.div`
  position: relative;
  height: 100%;
  background-color: ${constants.colorBgDefault};
  display: grid;
  grid-template-rows: 85px calc(100vh - 85px); /* height limitation on second row */
  grid-template-areas:
    "header"
    "content";

  *::selection {
    background-color: #20c99681;
  }

  table th {
    padding: 0 20px;
  }

  table td {
    padding: 5px 15px;
    background-color: rgb(50 50 40 / 40%);
  }

  code[property="inline"] {
    background-color: #332920;
    color: #e49400;
    padding: 0 4px;
    border-radius: ${constants.borderRadius};
    border: 1px solid rgb(228 148 0 / 20%);
    font-style: italic;
  }

  blockquote {
    border-radius: ${constants.borderRadius};
    padding: 10px 40px;
    background-color: rgb(43, 43, 43);
    font-style: italic;
    font-size: 18px;
  }

  @media ${device.tablet} {
    grid-template-columns: 300px auto;
    grid-template-areas:
      "sidebar content"
      "sidebar content"
      "sidebar content";
  }
`;

export default App;
