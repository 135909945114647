import i18next from "i18next";
import { initReactI18next } from "react-i18next";

function i18n() {
  i18next.use(initReactI18next).init({
    // (tip move them in a JSON file and import them,
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
          // sectionTitleAbout: "I'm <1>{{Toomas}}</1>, a software developer",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === "highlight") {
          console.log("Highlight", value);
          return value;
        }
        return value;
      },
    },
  });
}

export default i18n;
