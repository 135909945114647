import React from "react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownProps } from "react-markdown/lib/ast-to-react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import remarkGfm from "remark-gfm";

export interface MarkdownProps {
  content: string;
}

type PropsType = React.ClassAttributes<HTMLElement> &
  React.HTMLAttributes<HTMLElement> &
  ReactMarkdownProps & {
    inline?: boolean | undefined;
  };

const Markdown: React.FC<MarkdownProps> = ({ content }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[
        [
          remarkGfm,
          {
            singleTilde: false,
          },
        ],
      ]}
      children={content}
      components={{
        code({ node, className, inline, children, ...rest }) {
          return codeParser(className, inline, children, rest);
        },
      }}
      transformImageUri={src => {
        return `${src}?access_token=EeBnWro2uJPy9x8CXoKmduUyUby9iDSNb9B7g`;
      }}
    />
  );
};

function codeParser(
  className: PropsType["className"],
  inline: PropsType["inline"],
  children: PropsType["children"],
  rest: any,
): React.ReactNode {
  const match = /language-(\w+)/.exec(className || "");
  return !inline && match ? (
    <SyntaxHighlighter
      children={String(children).replace(/\n$/, "")}
      style={a11yDark}
      language={match[1]}
      PreTag="div"
      showLineNumbers
    />
  ) : (
    <code property="inline" className={className} {...rest}>
      {children}
    </code>
  );
}

export default Markdown;
