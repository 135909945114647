import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import constants from "../../Shared/constants";

interface NavigationLinkProps {
  url: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ url, children }) => {
  return (
    <NavigationElement exact to={url}>
      {children}
    </NavigationElement>
  );
};

const NavigationElement = styled(NavLink)`
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color: ${constants.colorText};
  text-transform: uppercase;
  text-decoration: none;

  &.active,
  &:hover {
    font-style: italic;
    color: ${constants.colorHighlightGreen};
  }
`;

export default NavigationLink;
